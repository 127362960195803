import { configureStore } from "@reduxjs/toolkit";

import authReducers from "@/store/authSlice";

import { loadStateForReduxFromLocalStorage } from "@/helpers";

const persistedState = loadStateForReduxFromLocalStorage() || {
  authState: {},
};

export const store = configureStore({
  reducer: {
    authState: authReducers.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
  preloadedState: {
    authState: {
      ...authReducers.getInitialState(),
      ...persistedState.authState,
    },
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
