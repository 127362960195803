import { PayloadAction, createSlice } from "@reduxjs/toolkit";

type HomeState = {
  currentUser: any;
};

const initialState: HomeState = {
  currentUser: {},
};

const authSlice = createSlice({
  name: "auth-state",
  initialState,
  reducers: {
    setCurrentUser(state, action: PayloadAction<object>) {
      state.currentUser = action.payload;
    },
  },
});

export const { setCurrentUser } = authSlice.actions;
export default authSlice;
