import { Button } from "@/components/ui/button";
import { store } from "@/store";
import debounce from "debounce";
import { saveStateForReduxFromLocalStorage } from "@/helpers";

const App = () => {
  // syncing with localStorage
  store.subscribe(
    // we use debounce to save the state once ea=ch 800ms
    // for better performances in case multiple changes occur in a short time
    debounce(() => {
      saveStateForReduxFromLocalStorage({
        authState: store.getState().authState,
      });
    }, 800)
  );

  return (
    <div>
      <div className="my-5 text-2xl">Welcome to Plinkbox</div>
      <Button
        variant="secondary"
        size="sm"
      >
        shadcn btn
      </Button>
    </div>
  );
};

export default App;
